import "amazon-connect-streams";
import { useEffect, useCallback, useState } from "react";
import SbuxCard from "../../../components/SbuxCard";
import OverLayCcp from "./OverlayCcp";
import CallerIdOverlayCcp from "./CallerIdOverlayCcp";
import { useDispatch, useSelector } from "react-redux";
import { getCcpInitializedStatus, getCcpAgent } from "../../../services/ccp";
import { getFeatureFlags } from "../../../services/featureFlags";
import { getCallHistory } from "../../../services/history";
import {
  getSettings,
  createOrUpdateLocaleSettings,
  getCCPLocaleStatus,
} from "../../../services/settings";
import { getGlobalConfig } from "../../../services/globalConfig";
import languageConfig from "../../../components/SbuxSettings/config/language.json";
import { getMetricsQueue, getMyMetricsData } from "../../../services/metrics";
import { getResources } from "../../../services/resources";
import { getMainHours } from "../../../services/mainHours";
import { getMediaMgt } from "../../../services/mediaMgt";
import { getClosureMsg } from "../../../services/closureMsg";
import { getTemporaryMsg } from "../../../services/temporaryMsg";
import { getTabsStatus } from "../../../services/tabs";
import { setOutboundCallerId } from "../../../services/outboundCall";
import { clientLog } from "../../../services/logging";
import { logMessage } from "../../../utils/amplifyLogger";
import styles from "../styles";
import useCss from "../../../hooks/useCss";
import {
  getCcpQueuesSelector,
  getCcpAgentRoutingNameSelector,
} from "../../../selectors/ccpSelector";
import {
  getConnectedInstanceSelector,
  getIsOutboundPhoneEnabledSelector,
  getOutboundPhonesSelector,
} from "../../../selectors/userInstanceSelector";
import {
  getLocaleSelector,
  getCcpLocaleStatusSelector,
  getTranslatedStatusSelector,
} from "../../../selectors/settingsSelector";

const InitCcp = ({ ccpUrl, loginUrl }) => {
  const dispatch = useDispatch();
  const classes = useCss(styles);

  const queues = useSelector(getCcpQueuesSelector);
  const agentRoutingName = useSelector(getCcpAgentRoutingNameSelector);
  const connectedInstance = useSelector(getConnectedInstanceSelector);
  const isOutboundPhoneEnabled = useSelector(getIsOutboundPhoneEnabledSelector);
  const outboundPhones = useSelector(getOutboundPhonesSelector);
  const locale = useSelector(getLocaleSelector);
  const ccpLocaleStatus = useSelector(getCcpLocaleStatusSelector);
  const translatedStatus = useSelector(getTranslatedStatusSelector);

  const instanceId = connectedInstance ? connectedInstance.instanceId : null;
  const instanceArn = connectedInstance ? connectedInstance.arn : null;
  const [isIframeRendered, setIsIframeRendered] = useState(false);

  const metricsPayload = {
    instanceId,
    queues,
  };
  const myMetricsPayload = {
    instanceArn,
  };

  const handleCcpInitialize = useCallback(async () => {
    await dispatch(getCcpInitializedStatus(true));
  }, []);

  const handleAgentInitialize = useCallback(async (agent) => {
    agent.getEndpoints(agent.getAllQueueARNs(), {
      success: async (data) => {
        await dispatch(
          getCcpAgent({
            agentStatus: agent.getStatus().name,
            configuration: agent.getConfiguration(),
            endpoints: data.endpoints,
          })
        );
      },
      failure: (err) => {
        logMessage(`Init CCP`, `Failed. ${err}`, `error`);
      },
    });
  }, []);

  const handleIframOnLoad = useCallback(() => setIsIframeRendered(true), []);

  useEffect(() => {
    try {
      if (instanceId) {
        // TODO: refactor to support on-deman module loading + api request
        // async closure
        (async () => {
          outboundPhones &&
            (await dispatch(setOutboundCallerId(outboundPhones?.[0].value)));
          await dispatch(getSettings());
          await dispatch(getGlobalConfig());
          await dispatch(
            createOrUpdateLocaleSettings(languageConfig.translations)
          );
          await dispatch(getCallHistory());
          await dispatch(getClosureMsg());
          await dispatch(getTemporaryMsg());
          await dispatch(getMainHours());
          await dispatch(getMediaMgt());
          await dispatch(getFeatureFlags());
        })();
      } else {
        dispatch(getTabsStatus("disabled"));
      }
    } catch (error) {
      dispatch(getTabsStatus("disabled"));
      clientLog({
        component: `initCCP`,
        message: `Something went wrong in calling all API => ${error}`,
      });
    }
  }, [instanceId, outboundPhones]);

  useEffect(() => {
    if (isIframeRendered) {
      const container = document.getElementById("ccp");
      window.connect?.core?.initCCP(container, {
        ccpUrl: ccpUrl,
        loginPopup: false,
        softphone: {
          allowFramedSoftphone: true,
        },
        loginOptions: {
          height: 400,
        },
        pageOptions: {
          enablePhoneTypeSettings: false,
        },
      });

      if (window.connect?.core) {
        window.connect.core.onInitialized(handleCcpInitialize);
      }

      if (window.connect?.agent) {
        window.connect.agent(handleAgentInitialize);
      } else {
        console.error("Amazon Connect not properly initialized");
      }
    }
  }, [isIframeRendered, ccpUrl, handleCcpInitialize, handleAgentInitialize]);

  useEffect(() => {
    if (!!agentRoutingName && instanceId) {
      Promise.allSettled([
        dispatch(getMetricsQueue(metricsPayload)),
        dispatch(getMyMetricsData(myMetricsPayload)),
        dispatch(getResources()),
      ])
        .then(() => dispatch(getTabsStatus("enabled")))
        .catch((error) => {
          dispatch(getTabsStatus("disabled"));
          clientLog({
            component: `initCCP`,
            message: `Something went wrong in calling all API => ${error}`,
          });
        });
    }
  }, [agentRoutingName, instanceId, metricsPayload, myMetricsPayload]);

  useEffect(() => {
    if (ccpLocaleStatus === "updated" && translatedStatus === "success") {
      (async () => {
        await window.connect.agent((agent) => {
          const config = agent.getConfiguration();
          const language = languageConfig.lanaguages.find(
            (f) => f.code === locale
          );
          const userPreferedLocale = language?.ccpCode || null;
          if (userPreferedLocale) {
            config.agentPreferences.locale = userPreferedLocale;
            agent.setConfiguration(config, {
              success: () => console.log("Success setting config"),
              failure: (err) => console.warn("Failed to set config", err),
            });
          }
        });
        await dispatch(getCCPLocaleStatus());
      })();
    }
  }, [ccpLocaleStatus, translatedStatus, locale]);

  return (
    <>
      {!isIframeRendered ? (
        <iframe
          className={classes.iframeDisplay}
          onLoad={handleIframOnLoad}
          src={loginUrl}
          id={"iframe"}
          frameBorder="0"
        />
      ) : (
        isIframeRendered && (
          <SbuxCard>
            <div
              id="ccp"
              style={
                isOutboundPhoneEnabled
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "-1px",
                      paddingBottom: "40px",
                    }
                  : {}
              }
              className={classes.ccpRoot}
            >
              <CallerIdOverlayCcp />
              <OverLayCcp />
            </div>
          </SbuxCard>
        )
      )}
    </>
  );
};

export default InitCcp;
