import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { setOutboundCallerId } from "../../../services/outboundCall";
import SbuxSelect from "../../../components/SbuxSelect";
import styles from "../styles";
import useCss from "../../../hooks/useCss";
import {
  getIsOutboundPhoneEnabledSelector,
  getOutboundPhonesSelector,
} from "../../../selectors/userInstanceSelector";
import { getIsCallEndedSelector } from "../../../selectors/outboundCallSelector";

const CallerIdOverlayCcp = () => {
  const isOutboundPhoneEnabled = useSelector(getIsOutboundPhoneEnabledSelector);
  const outboundPhones = useSelector(getOutboundPhonesSelector);
  const isCallEnded = useSelector(getIsCallEndedSelector);

  const dispatch = useDispatch();
  const classes = useCss(styles);
  const [selectedCallerId, setSelectedCallerId] = useState(
    outboundPhones?.[0].value
  );
  const outboundCallerIdList = outboundPhones?.reduce((acc, cur) => {
    acc.push({
      value: cur.value,
      name: `${cur.value} - ${cur.description}`,
    });
    return acc;
  }, []);

  useEffect(() => {
    isCallEnded && setSelectedCallerId(outboundPhones?.[0].value);
  }, [isCallEnded]);

  const handleCallerIdChange = async (value) => {
    isOutboundPhoneEnabled && (await dispatch(setOutboundCallerId(value)));
    setSelectedCallerId(value);
  };
  return (
    <>
      {isOutboundPhoneEnabled && (
        <Box className={classes.overlayDropdownRoot}>
          <Box className={classes.overlayDropdownLabel}>Outbound ID:</Box>
          <Box className={classes.overlayDropdown}>
            <SbuxSelect
              className={classes.outboundPhoneList}
              menuItems={outboundCallerIdList}
              defaultValue={selectedCallerId}
              value={selectedCallerId}
              handleSelectChange={(value) => handleCallerIdChange(value)}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default CallerIdOverlayCcp;
